.about > div.section {
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.about span.cursor {
    font-size: inherit;
    user-select: none;
}

.about .animating-icon {
    animation: animating-arrow 6000ms infinite;
    position: absolute;
    top: 75vh;
    width: 10vh;
    height: 10vh;
    opacity: 0;
}

.about span.cursor.animating {
    animation: cursor-blink 1000ms infinite;
    transition-duration: 400ms;
}

.about span.selected {
    background-color: lightblue;
}

.about div.mouse-cursor {
    width: 2vh;
    height: 2vh;
    color: white;
    position: relative;
    top: 25vh;
    left: 0vh; 
    transform: scale(1);
    z-index: 10;
}

.about .color-animation div.heading {
    animation: color-animation-heading 4500ms infinite;
}

.about .color-pallete > div {
    width: 4vh;
    height: 4vh;
    margin: 0 1.05vh;
    border: var(--border-width) solid var(--border);
    border-radius: var(--roundness);
    display: inline-block;
    z-index: 1;

}

.about .color-animation .color-pallete > div:nth-child(1) { animation: color-pallete-one 4500ms infinite; }
.about .color-animation .color-pallete > div:nth-child(2) { animation: color-pallete-two 4500ms infinite; }
.about .color-animation .color-pallete > div:nth-child(3) { animation: color-pallete-three 4500ms infinite; }
.about .color-animation .color-pallete > div:nth-child(4) { animation: color-pallete-four 4500ms infinite; }
.about .color-animation .color-pallete > div:nth-child(5) { animation: color-pallete-five 4500ms infinite; }

.about .color-animation > div.mouse-cursor {
    animation: color-animation-cursor 4500ms infinite;
}


@keyframes cursor-blink {
    0% { opacity: 1; }
    20% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes color-animation-cursor {
    0% { top: 25vh; left: 0vh; }
    
    20% { top: 3.25vh; left: -13vh; transform: scale(1); oopacity: 0.75; }
    22% { transform: scale(0.5); opacity: 1; color: white}
    30% { top: 3.25vh; left: -13vh; transform: scale(1); oopacity: 0.75;}
    
    35% { top: 3.25vh; left: -6.5vh; transform: scale(1); oopacity: 0.75; }
    37% { transform: scale(0.5); opacity: 1; color: white}
    45% { top: 3.25vh; left: -6.5vh; transform: scale(1); oopacity: 0.75;}
    
    50% { top: 3.25vh; left: 0vh; transform: scale(1); oopacity: 0.75; }
    52% { transform: scale(0.5); opacity: 1; color: white}
    60% { top: 3.25vh; left: 0vh; transform: scale(1); oopacity: 0.75;}
    
    65% { top: 3.25vh; left: 6.5vh; transform: scale(1); oopacity: 0.75; }
    67% { transform: scale(0.5); opacity: 1; color: white}
    75% { top: 3.25vh; left: 6.5vh; transform: scale(1); oopacity: 0.75;}
    
    80% { top: 3.25vh; left: 13vh; transform: scale(1); oopacity: 0.75; }
    82% { transform: scale(0.5); opacity: 1; color: white}
    90% { top: 3.25vh; left: 13vh; transform: scale(1); oopacity: 0.75;} 
    
    100% { top: 25vh; left: 0vh; }
}

@keyframes color-pallete-one {
    20% { transform: scale(1); }
    22% { transform: scale(0.5); }
    30% { transform: scale(1); }
}

@keyframes color-pallete-two {
    35% { transform: scale(1); }
    37% { transform: scale(0.5); }
    45% { transform: scale(1); }
}

@keyframes color-pallete-three {
    50% { transform: scale(1); }
    52% { transform: scale(0.5); }
    60% { transform: scale(1); }
}

@keyframes color-pallete-four {
    65% { transform: scale(1); }
    67% { transform: scale(0.5); }
    75% { transform: scale(1); }
}

@keyframes color-pallete-five {
    80% { transform: scale(1); }
    82% { transform: scale(0.5); }
    90% { transform: scale(1); }
}


@keyframes color-animation-heading {
    0% { color: white; }
    20% { color: white; }
    22% { color: red; }
    35% { color: red; }
    37% { color: blue; }
    50% { color: blue; }
    52% { color: green; }
    65% { color: green; }
    67% { color: yellow; }
    80% { color: yellow; }
    82% { color: white; }
}

@keyframes animating-arrow {
    60% {
        opacity: 0;
        margin-top: 0px;
    }

    80% {
        opacity: 0.5;
        margin-top: -50px;
    }
    
    100% {
        opacity: 0;
        margin-top: -100px;
    }
}