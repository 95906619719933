/* Button */

button {
    font-family: 'PoppinsBlack';
    outline: none;
    border-radius: var(--roundness);
    padding: 0.5vh 3vh;
    font-size: var(--text-size);
    margin: 0.3vh;
    outline: none;
    border: 0.5vh solid var(--accent);
    color: var(--accent-body-text) !important;
    background-color: var(--accent);
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

button.default .icon { color: var(--accent-body-text); }

button:active {
    transition-duration: 0ms;
    box-shadow: 0 0 10px -4px var(--accent-highlight);
    opacity: 0.5;
}

button.secondary {
    color: var(--accent) !important;
    border: 0.5vh solid var(--accent);
    background-color: var(--accent-body-text);
}

button.success {
    color: var(--success-body-text) !important;
    border: 0.5vh solid var(--success);
    background-color: var(--success);
}

button.success .icon { color: var(--success-body-text); }

button.danger {
    color: var(--danger-body-text) !important;
    border: 0.5vh solid var(--danger);
    background-color: var(--danger);
}

button.danger .icon { color: var(--danger-body-text); }

button.neutral {
    color: var(--neutral-body-text) !important;
    border: 0.5vh solid var(--neutral);
    background-color: var(--neutral);
}

button.neutral .icon { color: var(--neutral-body-text); }

button[disabled] {
    opacity: 0.2;
    cursor: default;
}

.icon { width: 3vh; margin-right: 1vh; }
.icon.small { width: 2vh; }
.icon.large { width: 5vh; }
.icon.huge { width: 15vh; }
.icon.inline { margin-right: 0; }

.icon.primary {
    color: var(--accent-body-text);
}

/* Chip */

div.chip {
    display: flex;
    padding: 0.25vh 1.5vh;
    margin: 0.5vh;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-layer);
    user-select: none;

    border: 0.25vh solid var(--border);
    border-radius: var(--roundness);
}

div.chip.primary {
    background-color: var(--accent);
}

/* Error */

div.error-chip {
    font-family: 'Poppins';
    display: flex;
    padding: 0.5vh 1.5vh;
    margin: 0.5vh;
    justify-content: space-between;
    align-items: center;
    background-color: var(--danger-layer);
    user-select: none;
    color: var(--danger-highlight);

    border: 0.25vh solid var(--danger-highlight);
    border-radius: var(--roundness);
}

/* Card */

div.card {
    padding: 1vh 3vh;
    background-color: var(--background-layer);
    margin: 3vh 0;

    border: 0.25vh solid var(--border);
    border-radius: var(--roundness);
}

div.card > div.heading:first-child {
    margin-top: 2vh;
}

/* Heading */

div.heading {
    font-family: 'PoppinsBlack';
    color: var(--heading-text);
    margin: 2vh 0;
}

div.heading:not(.logo):not(.no-margin) { margin-top: 5vh; }

div.heading.small { font-size: 3vh; }
div.heading.normal { font-size: 3.75vh; }
div.heading.large { font-size: 4.5vh; }
div.heading.landing { font-size: 5.5vh; }
div.heading.logo { font-size: 4vh; letter-spacing: -0.3vh; user-select: none; }

/* Checkbox */

div.checkbox {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

div.checkbox-circle {
    width: 3vh;
    height: 3vh;
    display: inline-block;
    border-radius: 3vh;
    border: 0.25vh solid var(--border);
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
}

div.checkbox-circle .icon { color: var(--text) }
div.checkbox-circle.checked .icon { color: var(--accent-body-text) }

div.checkbox-circle.checked { background-color: var(--accent); }

/* Input */

div.input {
    padding: 1vh 1.5vh;
    background-color: var(--background-layer);

    border: 0.25vh solid var(--border);
    border-radius: var(--roundness);
}

div.input > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Input Group */

div.input-group .input:not(:first-child):not(:last-child) { border-radius: 0px; }

div.input-group .input:first-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

div.input-group .input:not(:last-child) {
    border-bottom: none;
}

div.input-group .input:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

/* Loading */

div.loader .loader-dot{
    width: 2vh;
    height: 2vh;
    display: inline-block;
    background-color: white;
    margin: 0 0.75vh;
    border-radius: var(--roundness);
}

div.loader .loader-dot.l { animation: loader-left 750ms infinite; }
div.loader .loader-dot.m { animation: loader-middle 750ms infinite; }
div.loader .loader-dot.r { animation: loader-right 750ms infinite; }

/* ========== */

@keyframes loader-left {
    0% { opacity: 0; }    
    20% { opacity: 1; }    
    40% { opacity: 0.5; }    
    60% { opacity: 0; }    
    80% { opacity: 0; }    
    100% { opacity: 0; }    
}

@keyframes loader-middle {
    0% { opacity: 0; }    
    20% { opacity: 0; }    
    40% { opacity: 1; }    
    60% { opacity: 0.5; }    
    80% { opacity: 0; }    
    100% { opacity: 0; }    
}

@keyframes loader-right {
    0% { opacity: 0; }    
    20% { opacity: 0; }    
    40% { opacity: 0; }    
    60% { opacity: 1; }    
    80% { opacity: 0.5; }    
    100% { opacity: 0; }    
}

@media screen and (min-width: 1000px) {
    button.default:not([disabled]):hover {
        background-color: var(--accent-highlight);
        border: 0.5vh solid var(--accent-highlight);
        box-shadow: 0 0 20px -8px var(--accent-highlight);
    }
    
    button.secondary:not([disabled]):hover {
        color: var(--accent-highlight) !important;
        border: 0.5vh solid var(--accent-highlight);
    }

    button.success:not([disabled]):hover {
        background-color: var(--success-highlight);
        border: 0.5vh solid var(--success-highlight);
        box-shadow: 0 0 20px -8px var(--success-highlight);
    }

    button.danger:not([disabled]):hover {
        background-color: var(--danger-highlight);
        border: 0.5vh solid var(--danger-highlight);
        box-shadow: 0 0 20px -8px var(--danger-highlight);
    }

    button.neutral:not([disabled]):hover {
        background-color: var(--neutral-highlight);
        border: 0.5vh solid var(--neutral-highlight);
        box-shadow: 0 0 20px -8px var(--neutral-highlight);
    }

    div.card.small { width: 50%; }

    div.input.small input { width: 25vh; }
    div.input.medium input { width: 40vh; }
    div.input.large input { width: 65vh; }

    div.checkbox:hover div.checkbox-circle { background-color: var(--background-layer); }
    div.checkbox:hover div.checkbox-circle.checked { background-color: var(--accent-highlight); }
    div.checkbox:active { transition-duration: 0ms; opacity: 0.5; }
}