:root {
    --accent: rgb(50, 165, 253);
    --accent-highlight: rgb(202, 232, 255);
    --accent-body-text: rgb(13, 41, 62);
    --accent-layer: rgba(50, 165, 253, 0.15);
    
    --success: rgb(92, 205, 73);
    --success-highlight: rgb(66, 246, 102);
    --success-body-text: rgb(215, 255, 220);
    --success-layer: rgb(92, 205, 73, 0.15);
    
    --danger: rgb(202, 50, 50);
    --danger-highlight: rgb(255, 96, 96);
    --danger-body-text: rgb(255, 215, 215);
    --danger-layer: rgb(202, 50, 50, 0.15);
    
    --neutral: rgb(232, 232, 232);
    --neutral-highlight: rgb(255, 255, 255);
    --neutral-body-text: rgb(42, 42, 42);
    
    --background: #000000;
    --background-layer: rgba(255, 255, 255, 0.05);
    --heading-text: #ffffff;
    --text: #aaaaaa;
    --code-text: #ffffff;
    --hinted-text: #888888;
    --border: #262626;
    
    --border-width: 0.25vh;
    --text-size: 2.5vh;
    --roundness: 4vh;
}
