@font-face {
	font-family: "PoppinsBlack";
	src: local("PoppinsBlack"), url("./fonts/Poppins/Poppins-Black.ttf") format("truetype");
	font-weight: bolder;
}

@font-face {
	font-family: "PoppinsBold";
	src: local("PoppinsBold"), url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Poppins";
	src: local("Poppins"), url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "PoppinsThin";
	src: local("PoppinsThin"), url("./fonts/Poppins/Poppins-Thin.ttf") format("truetype");
	font-weight: light;
}

@font-face {
	font-family: "RobotoItalic";
	src: local("RobotoItalic"), url("./fonts/Roboto/Roboto-Italic.ttf") format("truetype");
	font-style: italic;
}

@font-face {
	font-family: "RobotoBold";
	src: local("RobotoBold"), url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "RobotoBoldItalic";
	src: local("RobotoBoldItalic"), url("./fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto"), url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "RobotoThin";
	src: local("RobotoThin"), url("./fonts/Roboto/Roboto-Thin.ttf") format("truetype");
	font-weight: light;
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

div#root {
	display: flex;
	justify-content: center;
}