.app {
	width: 800px;
}



body {
	background-color: var(--background);
	color: var(--text);
}

* {
	transition-duration: 100ms;
	font-size: 2vh;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'PoppinsBlack';
	color: var(--heading-text);
}

.landing > p {
	font-family: 'Poppins';
	font-size: 3vh;
}

.landing.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.landing.center * {
	text-align: center;
}

.post-content {
	line-height: 2;
	text-align: justify;
	margin: 5vh 0;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: var(--border-width) solid var(--border);
	margin-bottom: 5vh;
}

.header .menu-items {
	display: flex;
	justify-content: center;
	align-items: center;
}

.header .menu-button {
	visibility: hidden;
}

.caps {
	font-family: 'Poppins';
	font-size: 1.75vh;
	text-transform: uppercase;
	color: var(--hinted-text);
	letter-spacing: 0.2vh;
}

pre, .mono, code {
	font-family: monospace;
	background-color: var(--background-layer);
	border: .25vh solid var(--border);
	color: var(--code-text);
	font-size: inherit;
}

pre {
	padding: 1vh;
}

.caps.primary {
    color: var(--accent-body-text);
	letter-spacing: 0.19vh;
	font-weight: bold;
}

a {
	font-family: 'Poppins';
	color: var(--accent);
	text-decoration: none;
}

img {
	border-radius: var(--roundness);
	display: block;
	border: var(--border-width) solid var(--border)
}

input {
	font-family: 'Poppins';
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--heading-text);
	font-size: var(--text-size);
}

hr {
	margin: 2vh 0;
	border: none;
	border-bottom-width: var(--border-width);
	border-bottom-color: var(--border);
	border-bottom-style: solid;
	border-radius: var(--roundness);
}

.accent-color {
	color: var(--accent);
}

.padded {
	padding: 2vh;
}

.inline {
	display: inline-block;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-center.row { flex-direction: row; }
.flex-center.column { flex-direction: column; }

.mobile-size { width: 50%; }
.full-width { width: 100%; }



@media screen and (max-width: 1000px) {
	.app {
		width: 90%;
		padding-bottom: 4vh;
	}
	.app * {
		font-size: var(--text-size);
	}

	input {
		width: 100%;
	}

	.header {
		display: block;
		border: none;
	}
	
	.header .menu-items {
		width: calc(100%-2vh);
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		background-color: var(--background-layer);
		border: var(--border-width) solid var(--border);
		padding: 2vh;
	}
	
	.header .menu-items > *{
		padding: 2vh 0;
		display: flex;
		width: 100%;
	}

	.header .menu-button {
		visibility: visible;
	}

	img {
		width: 100%;
	}

	.mobile-size {
		width: 100%;
	}
}

@media screen and (min-width: 1000px) {
	a:hover {
		color: var(--accent-highlight);
	}
	.header .menu-items .menu-items-icon{
		display: none;
	}
}
